export default class News {
  constructor(
    title,
    subtitle,
    content,
    editor,
    isPublished = false,
    images = [],
    sendMail = false,
  ) {
    this.title = title;
    this.subtitle = subtitle;
    this.content = content;
    this.editor = editor;
    this.is_published = isPublished;
    this.images = images;
    this.send_mail = sendMail;
  }
}
